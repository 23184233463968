import { SortType } from '../providers/SearchProfilesPage/types'
import { transformToQuery } from '../utils/query'
import getConfigVariable from '../utils/getConfigVariable'
import { BreadcrumbObject, SpecialistProfile } from '../types/specialist'
import { ProfilePeerRecommendation } from '../types/peerRecommendations'
import { Keywords, Percentage, ProfileReview } from '../types/reviews'
import { NUMBER_PER_PROFILE_REQUEST } from '../constants/search'
import { PracticeProfile, SpecialistProfileWithAddress } from '../types'
import apiRequest from './request'
import { BookingSlotsResponse } from '../types/booking'
import { getDateWithOffset } from '../utils/date'

const basePath = getConfigVariable('BASIC_API_URL')
const internalApiUrl = getConfigVariable('INTERNAL_API_URL')

export const getSpecialistBySlug = async ({
  lang,
  slug
}: {
  lang: string
  slug: string
}): Promise<{
  result: SpecialistProfileWithAddress
  statusCode?: number
  message?: string
}> => apiRequest(`${basePath}/${lang}/specialists/${slug}`).then((r) => r.json())

export const getSponsoredSpecialistBySlug = async ({
  lang,
  city,
  keyword
}: {
  lang: string
  city: string
  keyword: string
}): Promise<{
  data: SpecialistProfile[]
}> => apiRequest(`${basePath}/${lang}/search/sponsored/specialist/${city}/${keyword}`).then((r) => r.json())

export const getBreadcrumbByLocationId = async ({
  lang,
  id
}: {
  lang: string
  id?: number
}): Promise<BreadcrumbObject | { statusCode: number }> => {
  let url = `${basePath}/${lang}/filters/locations`
  if (id) {
    url += `/${id}`
  }
  return apiRequest(url).then((r) => r.json())
}

export const getSpecialistReviews = async ({
  id,
  tenant,
  language,
  offset = 0,
  limit = 10,
  sort = 'desc',
  search = '',
  keyword = '',
  rating = [],
  date,
  replies = 0
}: {
  id: number | string
  tenant: string
  language?: string
  offset?: number
  limit?: number
  sort?: string
  search?: string
  keyword?: string
  rating?: number[]
  date?: number
  replies?: number
}): Promise<{
  result: ProfileReview[]
  totalCount: number
  percentage: Percentage
  keywordNames: Keywords[]
}> => {
  const query = transformToQuery({
    specialistId: id,
    offset,
    limit,
    sort,
    search,
    rating,
    keyword,
    days: date,
    language,
    replies: !!replies
  })
  return apiRequest(`${basePath}/${tenant}/reviews/?${query}`)
    .then((r) => r.json())
    .then((r) => r || {})
}

export const getSpecialistLatestReview = async ({
  id,
  tenant,
  language
}: {
  id: number | string
  tenant: string
  language: string
}): Promise<ProfileReview> => {
  const query = transformToQuery({
    specialistId: id,
    latest: 'true',
    language
  })

  return apiRequest(`${basePath}/${tenant}/reviews/?${query}`)
    .then((r) => r.json())
    .then((r) => r.result)
}

export const getSpecialistPeerRecommendations = async ({
  id,
  lang,
  offset = 0,
  limit = 10,
  sort = 'desc',
  search = '',
  keyword = ''
}: {
  id: number | string
  lang: string
  offset?: number
  limit?: number
  sort?: string
  search?: string
  keyword?: string
}): Promise<{
  result: ProfilePeerRecommendation[]
  totalCount: number
}> => {
  const queryString = transformToQuery({
    specialistId: id,
    limit,
    offset,
    sort,
    search,
    keyword
  })
  return apiRequest(`${basePath}/${lang}/peer-recommendations/?${queryString}`)
    .then((r) => r.json())
    .then((r) => r || {})
}

export const getSpecialistLastPeerRecommendation = async ({
  id,
  lang
}: {
  id: number | string
  lang: string
}): Promise<ProfilePeerRecommendation> =>
  apiRequest(`${basePath}/${lang}/peer-recommendations/?specialistId=${id}&latest=true`)
    .then((r) => r.json())
    .then((r) => r.result)

export const getNearBy = async ({
  slug,
  lang
}: {
  slug: string
  lang: string
}): Promise<{
  specialists: {
    total: number
    data: SpecialistProfile[]
    distance: string
  }
  practices: {
    total: number
    data: PracticeProfile[]
    distance: string
  }
}> =>
  apiRequest(`${basePath}/${lang}/specialists/${slug}/near-by`)
    .then((r) => r.json())
    .then((r) => r || {})

export const getSearchSpecialists = async ({
  lang,
  specialties,
  lat,
  lon,
  page,
  distance = '',
  sortType,
  expand = true,
  locationType = '',
  type = ''
}: {
  lang: string
  specialties?: string[]
  lat: string
  lon: string
  distance?: string
  page: number
  sortType: SortType
  expand?: boolean
  locationType?: string | null
  type?: string
}): Promise<{
  data: SpecialistProfile[]
  total: number
  distance: string
}> => {
  const filters: Record<string, number | string | string[] | undefined | boolean | null> = {
    distance,
    specialties,
    lat,
    lon,
    sortType,
    limit: NUMBER_PER_PROFILE_REQUEST,
    offset: (page - 1) * NUMBER_PER_PROFILE_REQUEST,
    expand,
    locationType,
    type
  }
  const query = transformToQuery(filters)
  return apiRequest(`${basePath}/${lang}/search/specialists?${query}`).then((r) => r.json())
}

export const putClaimProfileFormData = async ({
  data,
  lang,
  slug
}: {
  data: Record<string, string | number | undefined>
  lang: string
  slug: string
}) => {
  const url = `${internalApiUrl}/${lang}/api/specialists/${slug}/claim-profile`

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const response = await apiRequest(url, options)

  return response.ok
}

export const putAutoJourneyFormData = async ({
  data,
  lang,
  slug,
  profileType,
  requestHeaders = {}
}: {
  data: Record<string, string | number | undefined>
  lang: string
  slug: string
  profileType: string
  requestHeaders?: Record<any, unknown>
}) => {
  const url = `${internalApiUrl}/${lang}/api/${profileType}/${slug}/auto-login-claim-profile${window.location.search}`

  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...requestHeaders },
    body: JSON.stringify(data)
  }

  const response = await apiRequest(url, options)

  return response.ok
}

export const getSpecialistSlotsBySlug = async ({
  lang,
  slug
}: {
  lang: string
  slug: string
}): Promise<
  | {
      statusCode?: number
      message?: string
    }
  | BookingSlotsResponse
> => {
  const dateQuery = getDateWithOffset(2)
  return apiRequest(`${internalApiUrl}/${lang}/api/specialists/${slug}/booking-slots?startDate=${dateQuery}`).then(
    (r) => r.json()
  )
}

export const bookSpecialistSlot = async ({
  data,
  lang,
  id,
  requestHeaders = {}
}: {
  data: Record<string, string | number | undefined>
  lang: string
  id: number
  requestHeaders?: Record<any, unknown>
}) => {
  const url = `${internalApiUrl}/${lang}/api/specialists/${id}/book-appointment`

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...requestHeaders },
    body: JSON.stringify(data)
  }

  const response = await apiRequest(url, options)

  return { success: response.ok, status: response.status }
}
